import { Navigate } from "react-router-dom";
import Dashboard from "../pages/Dashboard";
import BlogList from "../pages/Blog/BlogList/index";
import BlogGrid from "../pages/Blog/BlogGrid/index";
import BlogDetails from "../pages/Blog/BlogDetails";
import AddBlog from "../pages/Blog/AddBlog";
import Lessons from "../pages/Education/lessons";
import Article from "../pages/Articles/Article";
import ArticleDetails from "pages/Articles/ArticleDetail";
import Login from "pages/Authentication/login";
import Logout from "pages/Authentication/Logout";
import UserProfile from "pages/Authentication/user-profile";
import ForgotPassword from "pages/Authentication/ForgotPassword";
import SignUp from "pages/Authentication/Register";
import WebinarCalender from "pages/Webinars/WebinarCalender";
import Booking from "pages/Booking/booking";
import JobList from "../pages/JobPages/JobList";
import JobDetails from "../pages/JobPages/JobDetails";
import BonusRequests from "../pages/Bonus/Requests";
import ApprovedBonusRequests from "../pages/Bonus/approved";
import Rejects from "../pages/Bonus/reject";
import Monday from "../pages/monday/Monday";
import SeminarCalender from "pages/Seminars/SeminarCalender";
import AddMenus from "pages/Menus/AddMenus";
import AllMenus from "pages/Menus/AllMenus";
import AnalysisBlogList from "pages/AnalysisBlog/AnalysisBlogList/AnalysisBlogList";
import AnalysisBlogGridIndex from "pages/AnalysisBlog/AnalysisBlogGrid/AnalysisBlogGridIndex";
import AnalysisBlogDetails from "pages/AnalysisBlog/AnalysisBlogDetails";
import AddAnalysisBlog from "pages/AnalysisBlog/AddAnalysisBlog";
import MarketNewsList from "pages/MarketNews/MarketNewsList/MarketNewsList";
import MarketNewsDetails from "pages/MarketNews/MarketNewsDetails";
import AddMarketNews from "pages/MarketNews/AddMarketNews";
import MarketNewsGridIndex from "pages/MarketNews/MarketNewsGrid/MarketNewsGridIndex";

const authProtectedRoutes = [
  { path: "/dashboard", component: <Dashboard /> },
  { path: "/webinar", component: <WebinarCalender /> },
  { path: "/seminar", component: <SeminarCalender /> },
  { path: "/booking", component: <Booking /> },
  { path: "/blog-list", component: <BlogList /> },
  { path: "/blog-grid", component: <BlogGrid /> },
  { path: "/blog-details/:id", component: <BlogDetails /> },
  { path: "/add-blog", component: <AddBlog /> },

  { path: "/market-news-list", component: <MarketNewsList /> },
  { path: "/market-news-grid", component: <MarketNewsGridIndex /> },
  { path: "/market-news-details/:id", component: <MarketNewsDetails /> },
  { path: "/add-market-news", component: <AddMarketNews /> },

  { path: "/articles", component: <Article /> },
  { path: "/analysis-blog-list", component: <AnalysisBlogList /> },
  { path: "/analysis-blogs-grid", component: <AnalysisBlogGridIndex /> },
  { path: "/analysis-blog-detail/:id", component: <AnalysisBlogDetails /> },
  { path: "/add-analysis-blogs", component: <AddAnalysisBlog /> },
  { path: "/article-details/:id", component: <ArticleDetails /> },
  { path: "/educations", component: <Lessons /> },
  { path: "/profile", component: <UserProfile /> },
  { path: "/job-list", component: <JobList /> },
  { path: "/job-details/:id", component: <JobDetails /> },
  { path: "/profit/bonus", component: <BonusRequests /> },
  { path: "/approved/profit/bonus", component: <ApprovedBonusRequests /> },
  { path: "/rejected/profit/bonus", component: <Rejects /> },
  { path: "/add-menu", component: <AddMenus /> },
  { path: "/all-menus", component: <AllMenus /> },
  { path: "/", exact: true, component: <Navigate to="/dashboard" /> },
  {
    path: "/monday",
    exact: true,
    component: <Monday />,
  },
];

const publicRoutes = [
  { path: "/login", component: <Login /> },
  { path: "/logout", component: <Logout /> },
  { path: "/forgot-password", component: <ForgotPassword /> },
  { path: "/register", component: <SignUp /> },
];
export { authProtectedRoutes, publicRoutes };
