import React, { useEffect, useState } from "react";
import {
  Form,
  Card,
  CardBody,
  Col,
  Row,
  Container,
  Toast,
  ToastBody,
  ToastHeader,
  Spinner,
} from "reactstrap";
import { Editor } from "react-draft-wysiwyg";
import Select from "react-select";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import Breadcrumbs from "../../Components/Common/Breadcrumb";
import { EditorState, convertToRaw, convertFromRaw } from "draft-js";
import axios from "axios";

const AddMenus = ({ menu, setModal, fetchAllMenus }: any) => {
  const [editorState, setEditorState] = useState<EditorState>(() =>
    EditorState.createEmpty()
  );
  const [editorStateAr, setEditorStateAr] = useState<EditorState>(() =>
    EditorState.createEmpty()
  );
  const [menuName, setMenuName] = useState<string>(menu?.name || "");
  const [menuNameAr, setMenuNameAr] = useState<string>(menu?.nameAr || "");
  const [isLinked, setIsLinked] = useState<boolean>(
    menu?.belongTo ? true : false
  );
  const [selectedMenu, setSelectedMenu] = useState<any>(
    menu?.belongTo
      ? { value: menu?.belongTo?._id, label: menu?.belongTo?.name }
      : null
  );
  const [menuOptions, setMenuOptions] = useState<any>([]);
  const [toastVisible, setToastVisible] = useState<boolean>(false);
  const [toastMessage, setToastMessage] = useState<string>("");
  const [toastType, setToastType] = useState<string>("success");
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    if (menu && menu?.content && menu?.contentAr) {
      try {
        const rawContent = JSON.parse(menu?.content);
        const contentState = convertFromRaw(rawContent);
        setEditorState(EditorState.createWithContent(contentState));
        const rawContentAr = JSON.parse(menu?.contentAr);
        const contentStateAr = convertFromRaw(rawContentAr);
        setEditorStateAr(EditorState.createWithContent(contentStateAr));
      } catch (error) {
        console.error("Error parsing content:", error);
      }
    }
  }, [menu]);

  const onEditorStateChange = (newEditorState: EditorState) => {
    setEditorState(newEditorState);
  };

  const onEditorStateChangeAr = (newEditorStateAr: EditorState) => {
    setEditorStateAr(newEditorStateAr);
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    const contentState = editorState.getCurrentContent();
    const rawContent = convertToRaw(contentState);
    const contentStateAr = editorStateAr.getCurrentContent();
    const rawContentAr = convertToRaw(contentStateAr);
    const menuData = {
      name: menuName,
      nameAr: menuNameAr,
      content: JSON.stringify(rawContent),
      contentAr: JSON.stringify(rawContentAr),
      linkedMenu: isLinked,
      selectedMenu: selectedMenu && isLinked ? selectedMenu.value : null,
    };

    setLoading(true);
    let res;
    try {
      if (menu?._id) {
        res = await axios.post(
          `${process.env.REACT_APP_APIKEY}/api/update/menu`,
          {
            ...menuData,
            id: menu?._id,
          }
        );
      } else {
        res = await axios.post(
          `${process.env.REACT_APP_APIKEY}/api/create/menu`,
          menuData
        );
      }
      if (res?.success) {
        setToastMessage("Menu saved successfully!");
        setToastType("success");
        setToastVisible(true);
        setMenuName("");
        setMenuNameAr("");
        setIsLinked(false);
        if (menu?._id) {
          setModal(false);
          fetchAllMenus();
        } else {
          fetchMenus();
        }
        setSelectedMenu(null);
        setEditorState(EditorState.createEmpty());
        setEditorStateAr(EditorState.createEmpty());
        window.scrollTo(0, 0);
        setTimeout(() => {
          setToastVisible(false);
        }, 3000);
      } else {
        setToastMessage("Menu not saved successfully!");
      }
    } catch (error) {
      console.error("Error saving menu:", error);
      setToastMessage("Error saving menu. Please try again.");
      setToastType("error");
      setToastVisible(true);
      window.scrollTo(0, 0);
      setTimeout(() => {
        setToastVisible(false);
      }, 3000);
    } finally {
      setLoading(false);
    }
  };

  const fetchMenus = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_APIKEY}/api/get/all/menu/without/level`
      );

      const options = response.data.map((m) => {
        return { value: m._id, label: m.name };
      });

      setMenuOptions(options);
    } catch (error) {
      console.error("Error saving menu:", error);
    }
  };

  useEffect(() => {
    fetchMenus();
  }, []);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Wiki" breadcrumbItem="Add Menu" />
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <Row className="mb-4">
                    <label htmlFor="title" className="col-md-1 col-form-label">
                      Name
                    </label>
                    <div className="col-md-11">
                      <input
                        className="form-control"
                        type="text"
                        id="title"
                        placeholder="Write the title name here"
                        value={menuName}
                        onChange={(e) => setMenuName(e.target.value)}
                      />
                    </div>
                  </Row>
                  <Row className="mb-4" dir={"rtl"}>
                    <label
                      htmlFor="titleAr"
                      className="col-md-1 col-form-label"
                    >
                      الاسم
                    </label>
                    <div className="col-md-11">
                      <input
                        className="form-control"
                        type="text"
                        id="titleAr"
                        placeholder="اكتب اسم العنوان هنا"
                        value={menuNameAr}
                        onChange={(e) => setMenuNameAr(e.target.value)}
                      />
                    </div>
                  </Row>
                  <Row className="mb-3">
                    <Col className="col-12">
                      <div
                        className="form-check"
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <input
                          type="checkbox"
                          className="form-check-input custom-checkbox"
                          id="linkedMenu"
                          style={{ width: "15px", height: "15px" }}
                          checked={isLinked}
                          onChange={() => setIsLinked(!isLinked)}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="linkedMenu"
                          style={{
                            marginLeft: "7px",
                            fontSize: "15px",
                            fontWeight: "400",
                            paddingTop: "4px",
                          }}
                        >
                          This title name is linked with other menu
                        </label>
                      </div>
                    </Col>
                  </Row>
                  {isLinked && (
                    <>
                      <Row className="mb-3">
                        <Col className="col-12">
                          <label htmlFor="language" className="col-form-label">
                            Select Menu
                          </label>
                          <div>
                            <Select
                              id="language"
                              className="select2-selection"
                              value={selectedMenu}
                              onChange={setSelectedMenu}
                              options={menuOptions}
                            />
                          </div>
                        </Col>
                      </Row>
                    </>
                  )}
                  <Row className="mb-4">
                    <Col className="col-12">
                      <Form method="post">
                        <div
                          className="mt-3"
                          style={{ display: "flex", justifyContent: "center" }}
                        >
                          <h4>Menu Description English</h4>
                        </div>
                        <div>
                          <Editor
                            toolbarClassName="toolbarClassName"
                            wrapperClassName="wrapperClassName"
                            editorClassName="editorClassName customEditorHeight"
                            editorState={editorState}
                            onEditorStateChange={onEditorStateChange}
                          />
                        </div>
                        <div
                          className="mt-4"
                          style={{ display: "flex", justifyContent: "center" }}
                        >
                          <h4>Menu Description Arabic</h4>
                        </div>
                        <div>
                          <Editor
                            toolbarClassName="toolbarClassName"
                            wrapperClassName="wrapperClassName"
                            editorClassName="editorClassName customEditorHeight"
                            editorState={editorStateAr}
                            onEditorStateChange={onEditorStateChangeAr}
                          />
                        </div>
                        <div className="text-center mt-4">
                          <button
                            type="button"
                            className="btn btn-primary"
                            onClick={handleSubmit}
                            disabled={loading}
                          >
                            {loading ? (
                              <>
                                <Spinner size="sm" color="light" />{" "}
                                <span>Save Menu</span>
                              </>
                            ) : (
                              "Save Menu"
                            )}
                          </button>
                        </div>
                      </Form>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
        {toastVisible && (
          <div className="toast-container">
            <Toast
              className={`bg-${
                toastType === "success" ? "success" : "danger"
              } text-white`}
            >
              <ToastHeader>
                {toastType === "success" ? "Success" : "Error"}
              </ToastHeader>
              <ToastBody>{toastMessage}</ToastBody>
            </Toast>
          </div>
        )}
      </div>
    </React.Fragment>
  );
};

export default AddMenus;
