import React, { useState, useEffect } from "react";
import { HiMiniChevronRight, HiMiniChevronDown } from "react-icons/hi2";
import axios from "axios";
import { DragDropContext, Droppable, Draggable } from "@hello-pangea/dnd";
import Modaldailogue from "Components/Common/Modal";
import { toast } from "react-toastify";

const AllMenus = () => {
  const [loading, setLoading] = useState(true);
  const [openSections, setOpenSections] = useState({});
  const [menuOptions, setMenuOptions] = useState([]);
  console.log(menuOptions, "menuOptions");

  // Fetch menus and submenus from the API
  const fetchMenus = async () => {
    try {
      setLoading(true);
      const response = await axios.get(
        `${process.env.REACT_APP_APIKEY}/api/get/all/menu`
      );
      setMenuOptions(response.data);
    } catch (error) {
      console.error("Error fetching menus:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchMenus();
  }, []);

  // Toggle the opening/closing of menu sections
  const toggleSection = (section) => {
    setOpenSections((prev) => ({
      ...prev,
      [section]: !prev[section],
    }));
  };

  const updateMenuOrderInDB = async (menus) => {
    try {
      const updatedOrder = menus.map((menu, index) => ({
        _id: menu._id,
        order: index,
      }));

      await axios.put(`${process.env.REACT_APP_APIKEY}/api/update/menu/order`, {
        menus: updatedOrder,
      });
      toast.success("Menu order updated successfully!", { autoClose: 2000 });
    } catch (error) {
      toast.error("Error updating menu order", { autoClose: 2000 });
    }
  };

  const updateSubmenuOrderInDB = async (menuId, submenus) => {
    try {
      const updatedOrder = submenus.map((submenu, index) => ({
        _id: submenu._id,
        order: index, // New order
      }));

      await axios.put(
        `${process.env.REACT_APP_APIKEY}/api/update/menu/${menuId}/submenu/order`,
        { submenus: updatedOrder }
      );
      toast.success("Submenu order updated successfully!", { autoClose: 2000 });
    } catch (error) {
      toast.error("Error updating submenu order", { autoClose: 2000 });
    }
  };

  const findAndUpdateSubmenu = (
    menus,
    droppableId,
    sourceIndex,
    destinationIndex
  ) => {
    return menus.map((menu) => {
      if (`submenu-${menu._id}` === droppableId) {
        // Found the parent menu; reorder its submenus
        const reorderedSubmenus = [...menu.submenus];
        const [removed] = reorderedSubmenus.splice(sourceIndex, 1);
        reorderedSubmenus.splice(destinationIndex, 0, removed);
        return { ...menu, submenus: reorderedSubmenus };
      }

      if (menu.submenus && menu.submenus.length > 0) {
        return {
          ...menu,
          submenus: findAndUpdateSubmenu(
            menu.submenus,
            droppableId,
            sourceIndex,
            destinationIndex
          ),
        };
      }

      return menu;
    });
  };
  const findParentMenu = (menus, droppableId) => {
    for (let menu of menus) {
      if (`submenu-${menu._id}` === droppableId) {
        return menu;
      }
      if (menu.submenus && menu.submenus.length > 0) {
        const found = findParentMenu(menu.submenus, droppableId);
        if (found) return found;
      }
    }
    return null;
  };
  const onDragEnd = async (result) => {
    const { destination, source, type } = result;

    if (!destination) return;

    let updatedMenus;

    if (type === "menu") {
      updatedMenus = Array.from(menuOptions);
      const [removed] = updatedMenus.splice(source.index, 1);
      updatedMenus.splice(destination.index, 0, removed);
      setMenuOptions(updatedMenus);

      await updateMenuOrderInDB(updatedMenus);
    } else if (type.startsWith("submenu")) {
      // Reorder nested submenus
      updatedMenus = findAndUpdateSubmenu(
        [...menuOptions],
        source.droppableId,
        source.index,
        destination.index
      );

      setMenuOptions(updatedMenus);

      const parentMenu = findParentMenu(updatedMenus, source.droppableId);

      if (parentMenu) {
        await updateSubmenuOrderInDB(parentMenu._id, parentMenu.submenus);
      }
    }
  };

  // Render submenus inside a menu
  const renderSubMenus = (submenus, parentId) => {
    return (
      <Droppable
        droppableId={`submenu-${parentId}`}
        type={`submenu-${parentId}`}
      >
        {(provided) => (
          <ul ref={provided.innerRef} {...provided.droppableProps}>
            {submenus?.map((submenu, index) => (
              <Draggable
                key={submenu._id}
                draggableId={submenu._id}
                index={index}
              >
                {(provided) => (
                  <li
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    className="submenu-li"
                  >
                    <div className="submenu-toggle dailogue-sec">
                      <div
                        className=""
                        onClick={() => toggleSection(submenu._id)}
                      >
                        {submenu.submenus && submenu.submenus.length > 0 && (
                          <span className="mr-[3px] text-[17px]">
                            {openSections[submenu._id] ? (
                              <HiMiniChevronDown />
                            ) : (
                              <HiMiniChevronRight />
                            )}
                          </span>
                        )}

                        <span>{submenu.name}</span>
                      </div>
                      <Modaldailogue menu={submenu} fetchMenus={fetchMenus} />
                    </div>
                    {openSections[submenu._id] &&
                      submenu.submenus?.length > 0 && (
                        <ul className="ml-4 mt-2 space-y-1 text-[#333]">
                          {renderSubMenus(submenu?.submenus, submenu?._id)}
                        </ul>
                      )}
                  </li>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </ul>
        )}
      </Droppable>
    );
  };

  // Render menus and submenus
  const renderMenu = (menus) =>
    menus.map((menu, menuIndex) => (
      <Draggable key={menu._id} draggableId={menu._id} index={menuIndex}>
        {(provided) => (
          <li
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            className="menu-item-li"
          >
            <div className="menu-item-toggle">
              <div className="dailogue-sec">
                <div className="" onClick={() => toggleSection(menu._id)}>
                  {menu.submenus?.length > 0 && (
                    <span className="mr-[3px] text-[17px]">
                      {openSections[menu._id] ? (
                        <HiMiniChevronDown />
                      ) : (
                        <HiMiniChevronRight />
                      )}
                    </span>
                  )}
                  <span>{menu.name}</span>
                </div>
                <Modaldailogue menu={menu} fetchMenus={fetchMenus} />
              </div>
            </div>
            {openSections[menu._id] && menu.submenus?.length > 0 && (
              <ul className="submenu-ul">
                {renderSubMenus(menu.submenus, menu?._id)}
              </ul>
            )}
          </li>
        )}
      </Draggable>
    ));

  return (
    <div className="page-content all-menus">
      {loading ? (
        <p>Loading...</p>
      ) : (
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="menus" type="menu">
            {(provided) => (
              <ul
                ref={provided.innerRef}
                {...provided.droppableProps}
                className="menu-ul"
              >
                {renderMenu(menuOptions)}
                {provided.placeholder}
              </ul>
            )}
          </Droppable>
        </DragDropContext>
      )}
    </div>
  );
};

export default AllMenus;
