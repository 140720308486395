import React, { useState, useEffect } from "react";
import {
  Card,
  Col,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
  Pagination,
  PaginationItem,
  PaginationLink,
} from "reactstrap";
import axios from "axios";

import classnames from "classnames";
import img1 from "../../../assets/images/small/img-2.jpg";
import img2 from "../../../assets/images/small/img-6.jpg";
import img3 from "../../../assets/images/small/img-1.jpg";
import { Link } from "react-router-dom";
import Spinners from "Components/Common/Spinner";
import { Editor, EditorState, convertFromRaw, ContentState } from "draft-js";
import Moment from "react-moment";
import withRouter from "Components/Common/withRouter";

const BlogGrid = (props: any) => {
  interface Blog {
    // Define the properties of a blog object here
    _id: string;
    title: string;
    image: string;
    publishDate: Date;
    content: string;
    isPublished: boolean;
    category: any;
    language: string;
    createdOn: Date;
    // ... other properties ...
  }

  const [activeTab, setActiveTab] = useState("1");
  const [loading, setLoading] = useState(true);
  const [blogs, setBlogs] = useState<Blog[]>([]);
  const [publishedPagination, setpublishedPagination] = useState() as any[];
  const [draftPagination, setDraftPagination] = useState() as any[];
  const [pages, setPages] = useState([]) as any[];
  const [publishedCurrent, setPublishedCurrent] = useState<number>(1);
  const [draftCurrent, setDraftCurrent] = useState<number>(1);
  const [publishedRequestPage, setPublishedRequestPage] = useState<number>(1);
  const [draftsRequestPage, setDraftsRequestPage] = useState<number>(1);
  const user = JSON.parse(localStorage.getItem("authUser"));

  const fetchPublishBlogs = async () => {
    const headers = {
      Authentication: `Bearer ${user?.token}`,
    };
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_APIKEY}/api/fetch/publish/blogs/${publishedRequestPage}/10`,
        { headers }
      );
      if (res.success) {
        let arr = [];
        for (let i = 1; i <= res.pagination?.totalPages; i++) {
          arr.push(i);
        }
        setPages(arr);
        setPublishedCurrent(res.pagination?.currentPage);
        setpublishedPagination(res.pagination);
        setBlogs(res.data);
        setLoading(false);
      }
    } catch (error) {
      console.error("Error fetching published blogs:", error);
      setLoading(false);
    }
  };

  const fetchDraftBlogs = async () => {
    const headers = {
      Authentication: `Bearer ${user?.token}`,
    };
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_APIKEY}/api/fetch/draft/blogs/${draftsRequestPage}/10`,
        { headers }
      );
      if (res.success) {
        let arr = [];
        for (let i = 1; i <= res.pagination?.totalPages; i++) {
          arr.push(i);
        }
        setPages(arr);
        setDraftCurrent(res.pagination?.currentPage);
        setDraftPagination(res.pagination);
        setBlogs(res.data);
        setLoading(false);
      }
    } catch (error) {
      console.error("Error fetching draft blogs:", error);
      setLoading(false);
    }
  };

  console.log(user, "user");

  const parseData = (data) => {
    try {
      const parsedData = JSON.parse(data);
      if (parsedData && parsedData.blocks) {
        const blocks = parsedData.blocks;
        const textContent = blocks.map((block) => block.text).join(" ");
        const selectedWords = textContent.slice(0, 30);
        const contentState = ContentState.createFromText(selectedWords);
        return EditorState.createWithContent(contentState);
      }
    } catch (error) {
      console.log("Error parsing JSON:", error);
    }
    return EditorState.createEmpty();
  };

  useEffect(() => {
    setLoading(true);
    if (activeTab === "1") {
      fetchPublishBlogs();
    } else if (activeTab === "2") {
      fetchDraftBlogs();
    }
  }, [activeTab, publishedRequestPage, draftsRequestPage]);

  return (
    <React.Fragment>
      <Col xl={9} lg={8}>
        <Card>
          <ul
            className="nav nav-tabs nav-tabs-custom justify-content-center pt-2"
            role="tablist"
          >
            <NavItem>
              <NavLink
                to="#"
                className={classnames({
                  active: activeTab === "1",
                })}
                onClick={() => {
                  setActiveTab("1");
                }}
              >
                Published
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                to="#"
                className={classnames({
                  active: activeTab === "2",
                })}
                onClick={() => {
                  setActiveTab("2");
                }}
              >
                Drafts
              </NavLink>
            </NavItem>
          </ul>
          <TabContent className="p-4" activeTab={activeTab}>
            <TabPane tabId="1">
              {loading ? (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "70vh",
                  }}
                  className="container"
                >
                  <Spinners />
                </div>
              ) : (
                <div>
                  <Row className="justify-content-center">
                    <Col xl={8}>
                      <div>
                        <div className="row align-items-center  ">
                          <div className="col-12">
                            <div className="justify-content-center d-flex mt-2">
                              <h5 className="mb-0">Published Blogs</h5>
                            </div>
                          </div>
                        </div>
                        <hr className="mb-4" />
                        <Row xs="2">
                          {blogs &&
                            blogs.map((blog, i) => (
                              <Col sm={6} xs={12} key={i}>
                                <Card className="p-1 border shadow-none">
                                  <div
                                    className="p-3"
                                    dir={blog.language === "ar" ? "rtl" : "ltr"}
                                  >
                                    <h5>
                                      <Link
                                        to={`/blog-details/${blog._id}`}
                                        className="text-dark"
                                      >
                                        {blog.title}
                                      </Link>
                                    </h5>
                                    <p className="text-muted mb-0">
                                      {" "}
                                      <Moment
                                        date={blog.publishDate}
                                        format="Do MMMM YYYY"
                                      />{" "}
                                    </p>
                                  </div>

                                  <div className="position-relative">
                                    <img
                                      src={`${blog?.image}`}
                                      alt={blog.title}
                                      className="img-thumbnail"
                                    />
                                  </div>
                                  <div
                                    className="p-3"
                                    dir={blog.language === "ar" ? "rtl" : "ltr"}
                                  >
                                    <ul className="list-inline pe-0">
                                      <li className={`list-inline-item`}>
                                        <Link to="#" className="text-muted">
                                          <i className="bx bx-comment-dots text-muted me-1"></i>{" "}
                                          12 Comments
                                        </Link>
                                      </li>
                                    </ul>
                                    <Editor
                                      editorState={parseData(blog.content)}
                                      readOnly={true}
                                    />
                                    <div>
                                      <Link
                                        to={`/blog-details/${blog._id}`}
                                        className="text-primary"
                                      >
                                        Read more{" "}
                                        <i className="mdi mdi-arrow-right"></i>
                                      </Link>
                                    </div>
                                  </div>
                                </Card>
                              </Col>
                            ))}
                        </Row>
                        <hr className="my-4" />
                        <div className="text-center">
                          <div className="d-flex justify-content-center">
                            <Pagination aria-label="Page navigation example">
                              <PaginationItem
                                disabled={publishedCurrent > 1 ? false : true}
                              >
                                <PaginationLink
                                  onClick={() =>
                                    setPublishedRequestPage((req) => req - 1)
                                  }
                                >
                                  <i className="mdi mdi-chevron-left" />
                                </PaginationLink>
                              </PaginationItem>

                              <PaginationItem active>
                                <PaginationLink>
                                  {publishedCurrent}
                                </PaginationLink>
                              </PaginationItem>

                              <PaginationItem
                                disabled={
                                  publishedCurrent <
                                  publishedPagination?.totalPages
                                    ? false
                                    : true
                                }
                              >
                                <PaginationLink
                                  onClick={() =>
                                    setPublishedRequestPage((req) => req + 1)
                                  }
                                >
                                  <i className="mdi mdi-chevron-right" />
                                </PaginationLink>
                              </PaginationItem>
                            </Pagination>
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
              )}
            </TabPane>

            <TabPane tabId="2">
              {loading ? (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "70vh",
                  }}
                  className="container"
                >
                  {" "}
                  <Spinners />{" "}
                </div>
              ) : (
                <div>
                  <Row className="justify-content-center">
                    <Col xl={8}>
                      <div>
                        <div className="row align-items-center  ">
                          <div className="col-12">
                            <div className="justify-content-center d-flex mt-2">
                              <h5 className="mb-0">Drafts</h5>
                            </div>
                          </div>
                        </div>
                        <hr className="mb-4" />
                        <Row xs="2">
                          {blogs &&
                            blogs.map((blog, i) => (
                              <Col sm={6} xs={12} key={i}>
                                <Card className="p-1 border shadow-none">
                                  <div className="p-3">
                                    <h5>
                                      <Link
                                        to={`/blog-details/${blog._id}`}
                                        className="text-dark"
                                      >
                                        {blog.title}
                                      </Link>
                                    </h5>
                                    <p className="text-muted mb-0">
                                      {" "}
                                      <Moment
                                        date={blog.publishDate}
                                        format="Do MMMM YYYY"
                                      />{" "}
                                    </p>
                                  </div>

                                  <div className="position-relative">
                                    <img
                                      src={`${blog?.image}`}
                                      alt={blog.title}
                                      className="img-thumbnail"
                                    />
                                  </div>
                                  <div className="p-3">
                                    <ul className="list-inline">
                                      <li className="list-inline-item me-3">
                                        <Link to="#" className="text-muted">
                                          <i className="bx bx-comment-dots align-middle text-muted me-1"></i>{" "}
                                          12 Comments
                                        </Link>
                                      </li>
                                    </ul>
                                    <Editor
                                      editorState={parseData(blog.content)}
                                      readOnly={true}
                                    />
                                    <div>
                                      <Link
                                        to={`/blog-details/${blog._id}`}
                                        className="text-primary"
                                      >
                                        Read more{" "}
                                        <i className="mdi mdi-arrow-right"></i>
                                      </Link>
                                    </div>
                                  </div>
                                </Card>
                              </Col>
                            ))}
                        </Row>
                        <hr className="my-4" />
                        <div className="text-center">
                          <div className="d-flex justify-content-center">
                            <Pagination aria-label="Page navigation example">
                              <PaginationItem
                                disabled={draftCurrent > 1 ? false : true}
                              >
                                <PaginationLink
                                  onClick={() =>
                                    setDraftsRequestPage((req) => req - 1)
                                  }
                                >
                                  <i className="mdi mdi-chevron-left" />
                                </PaginationLink>
                              </PaginationItem>

                              <PaginationItem active>
                                <PaginationLink>{draftCurrent}</PaginationLink>
                              </PaginationItem>

                              <PaginationItem
                                disabled={
                                  draftCurrent < draftPagination?.totalPages
                                    ? false
                                    : true
                                }
                              >
                                <PaginationLink
                                  onClick={() =>
                                    setDraftsRequestPage((req) => req + 1)
                                  }
                                >
                                  <i className="mdi mdi-chevron-right" />
                                </PaginationLink>
                              </PaginationItem>
                            </Pagination>
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
              )}
            </TabPane>
          </TabContent>
        </Card>
      </Col>
    </React.Fragment>
  );
};
export default withRouter(BlogGrid);
