import React from "react";
import { Container, Row } from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from "../../../Components/Common/Breadcrumb";

import AnalysisBlogGrid from "./AnalysisBlogGrid";
import RightBar from "../AnalysisBlogList/RightBar";

const AnalysisBlogGridIndex = () => {
  //meta title
  document.title = "Market News Page | PrimeX";
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title="Analysis Blogs"
            breadcrumbItem="Analysis Blogs Grid"
          />
          <Row>
            <AnalysisBlogGrid />
            <RightBar />
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default AnalysisBlogGridIndex;
