import React, { useState, useEffect } from "react";
import {
  Form,
  Card,
  CardBody,
  Col,
  Row,
  CardTitle,
  Container,
  Label,
  CardSubtitle,
  Button,
  Input,
  Toast,
  ToastHeader,
  ToastBody,
  UncontrolledAlert,
} from "reactstrap";
import { Editor } from "react-draft-wysiwyg";
// import { Button, Card, CardBody, Col, Container, Form, FormGroup, Input, InputGroup, , Row } from "reactstrap";
import Select from "react-select";
import { Link, useNavigate } from "react-router-dom";
import Dropzone from "react-dropzone";
import {
  EditorState,
  ContentState,
  convertToRaw,
  convertFromRaw,
} from "draft-js";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
//Import Breadcrumb
import Breadcrumbs from "../../Components/Common/Breadcrumb";
import axios from "axios";
import { GoDotFill } from "react-icons/go";

const AddAnalysisBlog = () => {
  //meta title
  const [selectedGroup, setselectedGroup] = useState(null) as any[];
  const [opt, setOpt] = useState(null) as any[];
  const [selectedGroupCat, setselectedGroupCat] = useState([]) as any[];
  const [titleEn, setTitleEn] = useState(null) as any[];
  const [titleAr, setTitleAr] = useState(null) as any[];
  const [slug, setSlug] = useState(null) as any[];
  const [subTitleEn, setSubTitleEn] = useState(null) as any[];
  const [subTitleAr, setSubTitleAr] = useState(null) as any[];
  const [blogImage, setBlogImage] = useState<string | null>(null);
  const [blogImageAr, setBlogImageAr] = useState<string | null>(null);
  const [errorMessage, setErrorMessage] = useState(null) as any[];
  const [successMessage, setSuccessMessage] = useState(null) as any[];
  const [toast, setToast] = useState(false);
  const [toastError, setToastError] = useState(false);
  const [editorState, setEditorState] = useState<EditorState>(() =>
    EditorState.createEmpty()
  );
  const [editorState2, setEditorState2] = useState<EditorState>(() =>
    EditorState.createEmpty()
  );
  const user = JSON.parse(localStorage.getItem("authUser"));

  const navigate = useNavigate();
  document.title = "Add Blog | PrimeX";

  const rtlStyle = {
    // Add your RTL styles here
    // You may also need to adjust other styles as per your design requirements
  };

  const [selectedMultipleFiles, setSelectedMultipleFiles] = useState<any>([]);
  const [blogImages, setBlogImages] = useState([]);
  const [selectedFiles, setselectedFiles] = useState<any>([]);
  const [selectedFilesAr, setSelectedFilesAr] = useState<any>([]);

  const onEditorStateChange = (newEditorState: EditorState) => {
    setEditorState(newEditorState);
  };

  const onEditorStateChange2 = (newEditorState2: EditorState) => {
    setEditorState2(newEditorState2);
  };

  function handleAcceptedFiles(files: any) {
    files.map((file: any) =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      })
    );
    setselectedFiles(files);
    console.log(files, "files");
  }

  function handleAcceptedFilesAr(files: any) {
    files.map((file: any) =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      })
    );
    setSelectedFilesAr(files);
    console.log(files, "files");
  }

  function handleAcceptedMultipleFiles(files: any) {
    files.map((file: any) =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      })
    );
    setSelectedMultipleFiles(files);
    console.log(files, "selectfiles");
  }

  const toggleToast = () => {
    setToast(!toast);
  };

  const customStyles = {
    control: (provided) => ({
      ...provided,
      zIndex: 9999, // Set your desired z-index value here
    }),
  };

  function formatBytes(bytes: any, decimals = 2) {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  }

  const handleUpload = async () => {
    console.log(selectedFiles[0], "selectedFiles");

    if (selectedFiles.length) {
      const file = selectedFiles[0];
      let _id = "1";
      console.log(file);
      const formData = new FormData();
      formData.append("files[]", file);
      formData.append("action", "attachments");
      formData.append("_id", _id);

      axios
        .post(`${process.env.REACT_APP_APIKEY}/api/upload`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((data) => {
          setSuccessMessage(
            "Image added successfully. Now Please add the content for english analysis blog to continue."
          );
          console.log(data.result.file_path, "file path");
          setToast(true);
          setBlogImage(data.result.file_path.toString());
          setselectedFiles([]);
        })
        .catch(function (error) {
          return { error: { message: error } };
        });
    } else {
      setErrorMessage("Please select Image to Upload.");
      setToastError(true);
    }
  };

  const handleUploadAr = async () => {
    console.log(selectedFilesAr[0], "selectedFilesAr");

    if (selectedFilesAr.length) {
      const file = selectedFilesAr[0];
      let _id = "1";
      console.log(file);
      const formData = new FormData();
      formData.append("files[]", file);
      formData.append("action", "attachments");
      formData.append("_id", _id);

      axios
        .post(`${process.env.REACT_APP_APIKEY}/api/upload`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((data) => {
          setSuccessMessage(
            "Image added successfully. Now Please add the content for arabic analysis blog to continue."
          );
          console.log(data.result.file_path, "file path");
          setToast(true);
          setBlogImageAr(data.result.file_path.toString());
          setSelectedFilesAr([]);
        })
        .catch(function (error) {
          return { error: { message: error } };
        });
    } else {
      setErrorMessage("Please select Image to Upload.");
      setToastError(true);
    }
  };

  const handleMultipleUpload = async () => {
    console.log(selectedMultipleFiles, "selectedMultipleFiles");

    if (selectedMultipleFiles.length) {
      const file = selectedFiles[0];
      let _id = "1";
      console.log(file);
      const formData = new FormData();
      selectedMultipleFiles.forEach((file) => {
        formData.append("files[]", file);
      });

      formData.append("action", "attachments");
      formData.append("_id", _id);

      axios
        .post(`${process.env.REACT_APP_APIKEY}/api/upload`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((data) => {
          console.log(data, "multidata");
          setSuccessMessage(
            "Image added successfully. Now Please add the content for market news to continue."
          );
          console.log(data.result.file_path, "file path");
          setToast(true);

          let results = [];
          if (data.result instanceof Array) {
            results = data.result.map((item) => item.result);
          } else {
            results = [data.result];
          }

          setBlogImages(results);
        })
        .catch(function (error) {
          return { error: { message: error } };
        });
    } else {
      setErrorMessage("Please select Image to Upload.");
      setToastError(true);
    }
  };

  const optionGroup2 = [
    {
      label: "Categories",
      options: opt,
    },
  ];

  const fetchCat = async () => {
    const headers = {
      Authentication: `Bearer ${user?.token}`,
    };
    const res = await axios.get(
      `${process.env.REACT_APP_APIKEY}/api/fetch/all/market-news/categories`,
      { headers }
    );
    if (res.success) {
      //   setCat(res.data)
      if (res?.data?.length) {
        let options = [];
        res?.data?.map((dat) => {
          options.push({ label: dat.titleEn, value: dat._id });
        });
        setOpt(options);
      } else {
        optionGroup2[0].label = "No Categories Available";
        optionGroup2[0].options = [];
      }
    } else {
      console.log("Server Error");
    }
  };

  useEffect(() => {
    fetchCat();
  }, []);

  useEffect(() => {
    if (toast) {
      const timer = setTimeout(() => {
        setToast(false);
        setSuccessMessage(null);
      }, 5000); // 10000 milliseconds (10 seconds)

      // Clear the timer if the component unmounts
      return () => clearTimeout(timer);
    }
    if (toastError) {
      const timer = setTimeout(() => {
        setToastError(false);
        setErrorMessage(null);
      }, 5000); // 10000 milliseconds (10 seconds)

      // Clear the timer if the component unmounts
      return () => clearTimeout(timer);
    }
  }, [toast, toastError]);

  const optionGroup = [
    {
      label: "Languages",
      options: [
        { label: "Arabic", value: "ar" },
        { label: "English", value: "en" },
      ],
    },
  ];

  function handleSelectGroup(selectedGroup: any) {
    setselectedGroup(selectedGroup);
  }

  function handleSelectGroupCat(selectedGroup: any) {
    setselectedGroupCat(selectedGroup);
  }

  console.log(typeof blogImage, "check image");

  const saveContent = async () => {
    if (blogImage == null || !blogImage) {
      setErrorMessage("no Image found");
      setToastError(true);
    }
    if (blogImageAr == null || !blogImageAr) {
      setErrorMessage("no Image found");
      setToastError(true);
    }
    if (!titleEn) {
      setErrorMessage("Please Add english Title");
      setToastError(true);
    } else if (!titleAr) {
      setErrorMessage("Please Add arabic Title");
      setToastError(true);
    } else if (!slug) {
      setErrorMessage("Please Add english Slug");
      setToastError(true);
    } else {
      const contentState = editorState.getCurrentContent();
      const contentRaw = convertToRaw(contentState);

      const contentStateAr = editorState2.getCurrentContent();
      const contentRawAr = convertToRaw(contentStateAr);

      console.log(contentRaw, "contentRaw");

      const data = {
        titleEn: titleEn,
        titleAr: titleAr,
        slug: slug,
        // subTitleEn: subTitleEn,
        // subTitleAr: subTitleAr,
        category: selectedGroupCat.value,
        image: blogImage,
        imageAr: blogImageAr,
        contentEn: JSON.stringify(contentRaw),
        contentAr: JSON.stringify(contentRawAr),
      };

      console.log(data.contentEn, "stringify");
      const headers = {
        Authentication: `Bearer ${user?.token}`,
      };

      const response = await axios.post(
        `${process.env.REACT_APP_APIKEY}/api/create/market-news`,
        data,
        { headers }
      );
      if (response.success) {
        setSuccessMessage("Analysis Blog is posted Successfully!");
        setToast(true);
        navigate("/analysis-blogs-grid");
      } else {
        setErrorMessage(
          response?.message?.message
            ? response?.message?.message
            : response?.message
        );
        setToastError(true);
      }
      console.log("Saved content:", contentRaw);
    }

    // You can save contentRaw to your state or database for later retrieval
  };
  console.log(selectedGroup);
  console.log(toast, "toast");
  console.log(blogImage, "image");

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title="Analysis Blog"
            breadcrumbItem="Add Analysis Blog"
          />
          <Row className="add-blog-area">
            <Col className="col-12">
              <Card>
                <CardBody>
                  {/* <Row className="mb-3">
                    <label
                      htmlFor="language"
                      className="col-md-2 col-form-label"
                    >
                      Select Category
                    </label>
                    <div className="col-md-10">
                      <Select
                        styles={customStyles}
                        value={selectedGroupCat}
                        // menuPortalTarget={document.body}
                        onChange={handleSelectGroupCat}
                        id="language"
                        options={optionGroup2}
                        className="select2-selection"
                      />
                    </div>
                  </Row> */}

                  <Row className="mb-3">
                    <label htmlFor="title" className="col-md-2 col-form-label">
                      Title
                    </label>
                    <div className="col-md-10">
                      <input
                        className="form-control"
                        type="text"
                        id="title"
                        placeholder="Write the analysis blog title here"
                        onChange={(e) => setTitleEn(e.target.value)}
                      />
                    </div>
                  </Row>
                  <Row className="mb-3" dir="rtl">
                    <label htmlFor="title" className="col-md-2 col-form-label">
                      عنوان
                    </label>
                    <div className="col-md-10" dir={"rtl"}>
                      <input
                        className="form-control .rtl"
                        type="text"
                        id="عنوان"
                        placeholder="قم بكتابة عنوان تحليل المقال هنا"
                        onChange={(e) => setTitleAr(e.target.value)}
                      />
                    </div>
                  </Row>

                  <Row className="mb-3">
                    <label htmlFor="slug" className="col-md-2 col-form-label">
                      Slug
                    </label>
                    <div className="col-md-10">
                      <input
                        className="form-control"
                        type="text"
                        id="slug"
                        placeholder="Write the analysis blog slug here"
                        onChange={(e) => setSlug(e.target.value)}
                      />
                    </div>
                  </Row>

                  <Row className="mb-3">
                    <label
                      htmlFor="language"
                      className="col-md-2 col-form-label"
                    >
                      Select Category
                    </label>
                    <div className="col-md-10">
                      <Select
                        styles={customStyles}
                        value={selectedGroupCat}
                        // menuPortalTarget={document.body}
                        onChange={handleSelectGroupCat}
                        id="language"
                        options={optionGroup2}
                        className="select2-selection"
                      />
                    </div>
                  </Row>

                  <h6 className="card-title mt-5">
                    English Analysis Blog Image
                  </h6>
                  <CardSubtitle className="mb-3">
                    {" "}
                    Please add one image for the english analysis blog.
                  </CardSubtitle>
                  <Form>
                    <Dropzone
                      accept={{
                        "image/jpeg": [],
                        "image/png": [],
                        "image/webp": [],
                        "image/heic": [],
                        "image/jfif": [],
                      }}
                      multiple={false}
                      onDrop={(acceptedFiles: any) => {
                        handleAcceptedFiles(acceptedFiles);
                      }}
                    >
                      {({ getRootProps, getInputProps }: any) => (
                        <div className="dropzone">
                          <div
                            className="dz-message needsclick mt-2"
                            {...getRootProps()}
                          >
                            <input {...getInputProps()} />
                            <div className="mb-3">
                              <i className="display-4 text-muted bx bxs-cloud-upload" />
                            </div>
                            <h4>Drop files here or click to upload.</h4>
                          </div>
                        </div>
                      )}
                    </Dropzone>
                    <div className="dropzone-previews mt-3" id="file-previews">
                      {selectedFiles.map((f: any, i: any) => {
                        return (
                          <Card
                            className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                            key={i + "-file"}
                          >
                            <div className="p-2">
                              <Row className="align-items-center">
                                <Col className="col-auto">
                                  <img
                                    data-dz-thumbnail=""
                                    height="80"
                                    className="avatar-sm rounded bg-light"
                                    alt={f.name}
                                    src={f.preview}
                                  />
                                </Col>
                                <Col>
                                  <Link
                                    to="#"
                                    className="text-muted font-weight-bold"
                                  >
                                    {f.name}
                                  </Link>
                                  <p className="mb-0">
                                    <strong>{f.formattedSize}</strong>
                                  </p>
                                </Col>
                              </Row>
                            </div>
                          </Card>
                        );
                      })}
                    </div>
                    <div
                      className="position-fixed top-0 end-0 p-3"
                      style={{ zIndex: "1005" }}
                    >
                      <Toast
                        isOpen={toast}
                        className="align-items-center text-white bg-success border-0 custom-toast"
                        role="alert"

                        // style={{ backgroundColor: "green" }}
                      >
                        <div className="d-flex">
                          <ToastBody>{successMessage}</ToastBody>
                          <button
                            onClick={() => setToast(false)}
                            type="button"
                            className="btn-close btn-close-white me-2 m-auto"
                          ></button>
                        </div>
                      </Toast>

                      <Toast
                        isOpen={toastError}
                        className="align-items-center text-white bg-danger border-0 custom-toast"
                        role="alert"

                        // style={{ backgroundColor: "green" }}
                      >
                        <div className="d-flex">
                          <ToastBody>{errorMessage}</ToastBody>
                          <button
                            onClick={() => setToastError(false)}
                            type="button"
                            className="btn-close btn-close-white me-2 m-auto"
                          ></button>
                        </div>
                      </Toast>
                    </div>
                  </Form>
                  <div className="text-center mt-4">
                    <button
                      type="button"
                      className="btn btn-primary"
                      onClick={handleUpload}
                    >
                      Save
                    </button>
                  </div>

                  <h6 className="card-title">Arabic Analysis Blog Image</h6>
                  <CardSubtitle className="mb-3">
                    {" "}
                    Please add one image for the arabic analysis blog.
                  </CardSubtitle>
                  <Form>
                    <Dropzone
                      accept={{
                        "image/jpeg": [],
                        "image/png": [],
                        "image/webp": [],
                        "image/heic": [],
                        "image/jfif": [],
                      }}
                      multiple={false}
                      onDrop={(acceptedFiles: any) => {
                        handleAcceptedFilesAr(acceptedFiles);
                      }}
                    >
                      {({ getRootProps, getInputProps }: any) => (
                        <div className="dropzone">
                          <div
                            className="dz-message needsclick mt-2"
                            {...getRootProps()}
                          >
                            <input {...getInputProps()} />
                            <div className="mb-3">
                              <i className="display-4 text-muted bx bxs-cloud-upload" />
                            </div>
                            <h4>Drop files here or click to upload.</h4>
                          </div>
                        </div>
                      )}
                    </Dropzone>
                    <div className="dropzone-previews mt-3" id="file-previews">
                      {selectedFilesAr.map((f: any, i: any) => {
                        return (
                          <Card
                            className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                            key={i + "-file"}
                          >
                            <div className="p-2">
                              <Row className="align-items-center">
                                <Col className="col-auto">
                                  <img
                                    data-dz-thumbnail=""
                                    height="80"
                                    className="avatar-sm rounded bg-light"
                                    alt={f.name}
                                    src={f.preview}
                                  />
                                </Col>
                                <Col>
                                  <Link
                                    to="#"
                                    className="text-muted font-weight-bold"
                                  >
                                    {f.name}
                                  </Link>
                                  <p className="mb-0">
                                    <strong>{f.formattedSize}</strong>
                                  </p>
                                </Col>
                              </Row>
                            </div>
                          </Card>
                        );
                      })}
                    </div>
                    <div
                      className="position-fixed top-0 end-0 p-3"
                      style={{ zIndex: "1005" }}
                    >
                      <Toast
                        isOpen={toast}
                        className="align-items-center text-white bg-success border-0 custom-toast"
                        role="alert"

                        // style={{ backgroundColor: "green" }}
                      >
                        <div className="d-flex">
                          <ToastBody>{successMessage}</ToastBody>
                          <button
                            onClick={() => setToast(false)}
                            type="button"
                            className="btn-close btn-close-white me-2 m-auto"
                          ></button>
                        </div>
                      </Toast>

                      <Toast
                        isOpen={toastError}
                        className="align-items-center text-white bg-danger border-0 custom-toast"
                        role="alert"

                        // style={{ backgroundColor: "green" }}
                      >
                        <div className="d-flex">
                          <ToastBody>{errorMessage}</ToastBody>
                          <button
                            onClick={() => setToastError(false)}
                            type="button"
                            className="btn-close btn-close-white me-2 m-auto"
                          ></button>
                        </div>
                      </Toast>
                    </div>
                  </Form>
                  <div className="text-center mt-4">
                    <button
                      type="button"
                      className="btn btn-primary"
                      onClick={handleUploadAr}
                    >
                      Save
                    </button>
                  </div>

                  <h6 className="card-title mt-md-3 mt-4">
                    Analysis Blog Images Links
                  </h6>
                  <CardSubtitle className="mb-3">
                    {" "}
                    Please add one or more images and get the links for use in
                    the analysis blog description.
                  </CardSubtitle>
                  <Form>
                    <div className="mt-3">
                      <Input
                        className="form-control"
                        type="file"
                        id="formFile"
                        multiple
                        onChange={(e) =>
                          handleAcceptedMultipleFiles([...e.target.files])
                        }
                      />
                    </div>
                  </Form>
                  <div className="text-center mt-4">
                    <button
                      type="button"
                      className="btn btn-primary"
                      onClick={handleMultipleUpload}
                    >
                      Save
                    </button>
                    <div>
                      {blogImages &&
                        blogImages.length > 0 &&
                        blogImages.map((image, i) => (
                          <div
                            className="d-flex align-items-center mt-1"
                            key={i}
                          >
                            <GoDotFill
                              style={{
                                marginRight: "6px",
                                color: "#4458b8",
                                fontSize: "16px",
                              }}
                            />
                            <p className="text-start m-0">{image.file_path}</p>
                          </div>
                        ))}
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col className="col-12">
              <Form method="post">
                <Card>
                  <CardBody>
                    <div
                      className="mt-3"
                      style={{ display: "flex", justifyContent: "center" }}
                    >
                      <h4>Analysis Blog Description in English</h4>
                    </div>

                    <div>
                      <Editor
                        toolbarClassName="toolbarClassName"
                        wrapperClassName="wrapperClassName"
                        editorClassName="editorClassName customEditorHeight"
                        editorState={editorState}
                        onEditorStateChange={onEditorStateChange}
                      />
                    </div>
                    <div
                      className="mt-3"
                      style={{ display: "flex", justifyContent: "center" }}
                    >
                      <h4>Analysis Blog Description in Arabic</h4>
                    </div>

                    <div className="mt-3">
                      <Editor
                        toolbarClassName="toolbarClassName"
                        wrapperClassName="wrapperClassName"
                        editorClassName="editorClassName customEditorHeight"
                        editorState={editorState2}
                        onEditorStateChange={onEditorStateChange2}
                      />
                    </div>

                    <div className="text-center mt-4">
                      <button
                        type="button"
                        className="btn btn-primary "
                        onClick={saveContent}
                      >
                        Save Analysis Blog
                      </button>
                    </div>
                  </CardBody>
                </Card>
              </Form>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default AddAnalysisBlog;
