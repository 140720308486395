import React, { useEffect, useState } from "react";
import { FaEdit, FaEye } from "react-icons/fa";
import { TbTrash } from "react-icons/tb";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Nav,
  NavItem,
  NavLink,
} from "reactstrap";
import { Editor as MyEditor } from "draft-js";
import { EditorState, convertFromRaw, CompositeDecorator } from "draft-js";
import AddMenus from "pages/Menus/AddMenus";
import axios from "axios";

const buttons = [
  { color: "warning", icon: <FaEye />, type: "view" },
  { color: "danger", icon: <TbTrash />, type: "delete" },
  { color: "info", icon: <FaEdit />, type: "edit" },
];

function Modaldailogue({ menu, fetchMenus }) {
  const [contentData, setContentData] = useState(menu?.content);
  const [contentAr, setContentAr] = useState(menu?.contentAr);
  const [modal, setModal] = useState(false);
  const [modalType, setModalType] = useState("");
  const [activeTab, setActiveTab] = useState("english");

  useEffect(() => {
    setContentData(menu?.content);
    setContentAr(menu?.contentAr);
  }, [menu]);
  const toggle = () => setModal(!modal);

  const ImageComponent = ({ src }) => (
    <img src={src} alt="PrimeX Capital" style={{ maxWidth: "100%" }} />
  );

  const mediaBlockRenderer = (block) => {
    if (block.getType() === "atomic") {
      return {
        component: MediaComponent,
        editable: false,
      };
    }
    return null;
  };

  const MediaComponent = (props) => {
    const entity = props.contentState.getEntity(props.block.getEntityAt(0));
    const type = entity.getType();
    const data = entity.getData();

    if (type === "IMAGE") {
      return <ImageComponent src={data.src} />;
    }
    return null;
  };

  const findLinkEntities = (contentBlock, callback, contentState) => {
    contentBlock.findEntityRanges((character) => {
      const entityKey = character.getEntity();
      return (
        entityKey !== null &&
        contentState.getEntity(entityKey).getType() === "LINK"
      );
    }, callback);
  };

  const DraftLink = (props) => {
    const { contentState, entityKey, children } = props;
    if (!contentState || !entityKey) {
      return null;
    }

    const entity = contentState.getEntity(entityKey);
    const { url } = entity.getData();
    return (
      <a href={url} target="_blank" rel="noopener noreferrer">
        {children}
      </a>
    );
  };

  const decorator = new CompositeDecorator([
    {
      strategy: findLinkEntities,
      component: DraftLink,
    },
  ]);

  const handleSubMenuClick = (submenuContent, submenuName) => {
    const contentState = convertFromRaw(JSON.parse(submenuContent));
    const editorState = EditorState.createWithContent(contentState, decorator);
    setContentData(editorState);
    // setBreadcrumb((prev) => {
    //   if (!prev.includes(submenuName)) {
    //     return [...prev, submenuName];
    //   }
    //   return prev;
    // });
  };

  const handleButtonClick = (type, cont) => {
    setModalType(type);

    if (type?.toLowerCase() === "view") {
      const contentState = convertFromRaw(JSON.parse(cont));
      const editorState = EditorState?.createWithContent(
        contentState,
        decorator
      );
      setContentData(editorState);
    }
    toggle();
  };

  //   delete menu
  const handleDelete = async (id) => {
    try {
      const res = await axios.delete(
        `${process.env.REACT_APP_APIKEY}/api/delete/menu/${menu?._id}`
      );

      if (res?.success) {
        fetchMenus();
        setModal(false);
      }
    } catch (error) {
      console.log(error, "error");
    }
  };
  const toggleTab = (tab) => {
    setActiveTab(tab);
  };

  return (
    <div>
      <div className="dialog-btn  ">
        {buttons.map((button, index) => (
          <Button
            className="mx-1"
            key={index}
            color={button.color}
            onClick={() => handleButtonClick(button.type, menu?.content)}
          >
            {button.icon}
          </Button>
        ))}
      </div>
      <Modal
        isOpen={modal}
        toggle={toggle}
        size={modalType === "edit" || modalType === "view" ? "xl" : ""}
      >
        <ModalHeader toggle={toggle}>
          {modalType === "delete"
            ? "Delete Item"
            : modalType === "edit"
            ? "Edit Item"
            : "View Item"}
        </ModalHeader>
        <ModalBody>
          {modalType === "delete" ? (
            <>
              <p>Are you sure you want to delete this item?</p>

              <ModalFooter>
                <Button color="primary" onClick={handleDelete}>
                  Delete
                </Button>
                <Button color="secondary" onClick={toggle}>
                  Cancel
                </Button>
              </ModalFooter>
            </>
          ) : modalType === "edit" ? (
            <AddMenus
              menu={menu}
              setModal={setModal}
              fetchAllMenus={fetchMenus}
            />
          ) : (
            <>
              <Nav tabs>
                <NavItem>
                  <NavLink
                    className={activeTab === "english" ? "active" : ""}
                    onClick={() => toggleTab("english")}
                  >
                    English
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={activeTab === "arabic" ? "active" : ""}
                    onClick={() => toggleTab("arabic")}
                  >
                    Arabic
                  </NavLink>
                </NavItem>
              </Nav>

              {activeTab === "english" ? (
                <MyEditor
                  editorState={contentData}
                  readOnly={true}
                  blockRendererFn={mediaBlockRenderer}
                />
              ) : (
                <MyEditor
                  editorState={EditorState.createWithContent(
                    convertFromRaw(JSON.parse(contentAr)),
                    decorator
                  )}
                  readOnly={true}
                  blockRendererFn={mediaBlockRenderer}
                />
              )}
            </>
          )}
        </ModalBody>
      </Modal>
    </div>
  );
}

export default Modaldailogue;
