import React from "react";
import { Container, Row } from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from "../../../Components/Common/Breadcrumb";

import MarketNewsGrid from "./MarketNewsGrid";
// import RightBar from "../MarketNewsList/RightBar";

const MarketNewsGridIndex = () => {
  //meta title
  document.title = "All Market News | PrimeX";
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Market News" breadcrumbItem="Market News Grid" />
          <Row>
            <MarketNewsGrid />
            {/* <RightBar /> */}
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default MarketNewsGridIndex;
