import React from "react";
import { Link } from "react-router-dom";

//import components
import SidebarContent from "./SidebarContent";

//import images
import logo from "../../assets/images/drawerLogoDark.webp";
import logoLightPng from "../../assets/images/drawerLogoDark.webp";
import logoLightSvg from "../../assets/images/drawerLogoDark.webp";
import logoDark from "../../assets/images/drawerLogoLight.webp";

const Sidebar = (props: any) => {
  return (
    <React.Fragment>
      <div className="vertical-menu">
        <div className="navbar-brand-box">
          <Link to="/" className="logo logo-dark">
            <span className="logo-sm">
              <img src={logo} alt="" width="70%" />
              {/* <h3 className="mt-4" style={{ color: "white" }}>
                PrimeX
              </h3> */}
            </span>
            <span className="logo-lg">
              <img src={logoDark} alt="" width="70%" />
              {/* <h3 className="mt-4" style={{ color: "white" }}>
                PrimeX
              </h3> */}
            </span>
          </Link>

          <Link to="/" className="logo logo-light">
            <span className="logo-sm">
              <img src={logoLightSvg} alt="" width="70%" />
              {/* <h3 className="mt-4" style={{ color: "white" }}>
                PrimeX
              </h3> */}
            </span>
            <span className="logo-lg">
              <img src={logoLightPng} alt="" width="70%" />
              {/* <h3 className="mt-4" style={{ color: "white" }}>
                PrimeX
              </h3> */}
            </span>
          </Link>
        </div>
        <div data-simplebar className="h-100">
          {props.type !== "condensed" ? <SidebarContent /> : <SidebarContent />}
        </div>
        <div className="sidebar-background"></div>
      </div>
    </React.Fragment>
  );
};

export default Sidebar;
