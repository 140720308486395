import React, { useEffect, useRef, useCallback, useState } from "react";
//Import Scrollbar
import SimpleBar from "simplebar-react";

// MetisMenu
import MetisMenu from "metismenujs";

import { Link } from "react-router-dom";

//i18n
import { withTranslation } from "react-i18next";
import withRouter from "../../Components/Common/withRouter";
import { FaCalendarAlt } from "react-icons/fa";
import { LuCalendarClock } from "react-icons/lu";
import { MdMonetizationOn } from "react-icons/md";

const SidebarContent = (props: any) => {
  const UserData = JSON.parse(localStorage.getItem("authUser"));
  const ref = useRef<any>();

  const activateParentDropdown = useCallback((item: any) => {
    item.classList.add("active");
    const parent = item.parentElement;
    const parent2El = parent.childNodes[1];

    if (parent2El && parent2El.id !== "side-menu") {
      parent2El.classList.add("mm-show");
    }

    if (parent) {
      parent.classList.add("mm-active");
      const parent2 = parent.parentElement;

      if (parent2) {
        parent2.classList.add("mm-show"); // ul tag

        const parent3 = parent2.parentElement; // li tag

        if (parent3) {
          parent3.classList.add("mm-active"); // li
          parent3.childNodes[0].classList.add("mm-active"); //a
          const parent4 = parent3.parentElement; // ul
          if (parent4) {
            parent4.classList.add("mm-show"); // ul
            const parent5 = parent4.parentElement;
            if (parent5) {
              parent5.classList.add("mm-show"); // li
              parent5.childNodes[0].classList.add("mm-active"); // a tag
            }
          }
        }
      }
      scrollElement(item);
      return false;
    }
    scrollElement(item);
    return false;
  }, []);

  console.log(UserData, "UserData");

  const removeActivation = (items) => {
    for (var i = 0; i < items.length; ++i) {
      var item = items[i];
      const parent = items[i].parentElement;

      if (item && item.classList.contains("active")) {
        item.classList.remove("active");
      }
      if (parent) {
        const parent2El =
          parent.childNodes && parent.childNodes.lenght && parent.childNodes[1]
            ? parent.childNodes[1]
            : null;
        if (parent2El && parent2El.id !== "side-menu") {
          parent2El.classList.remove("mm-show");
        }

        parent.classList.remove("mm-active");
        const parent2 = parent.parentElement;

        if (parent2) {
          parent2.classList.remove("mm-show");

          const parent3 = parent2.parentElement;
          if (parent3) {
            parent3.classList.remove("mm-active"); // li
            parent3.childNodes[0].classList.remove("mm-active");

            const parent4 = parent3.parentElement; // ul
            if (parent4) {
              parent4.classList.remove("mm-show"); // ul
              const parent5 = parent4.parentElement;
              if (parent5) {
                parent5.classList.remove("mm-show"); // li
                parent5.childNodes[0].classList.remove("mm-active"); // a tag
              }
            }
          }
        }
      }
    }
  };

  const activeMenu = useCallback(() => {
    const pathName = process.env.PUBLIC_URL + props.router.location.pathname;
    let matchingMenuItem = null;
    const ul: any = document.getElementById("side-menu");
    const items = ul.getElementsByTagName("a");
    removeActivation(items);

    for (let i = 0; i < items.length; ++i) {
      if (pathName === items[i].pathname) {
        matchingMenuItem = items[i];
        break;
      }
    }
    if (matchingMenuItem) {
      activateParentDropdown(matchingMenuItem);
    }
  }, [props.router.location.pathname, activateParentDropdown]);

  useEffect(() => {
    ref.current.recalculate();
  }, []);

  useEffect(() => {
    new MetisMenu("#side-menu");
  }, []);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    activeMenu();
  }, [activeMenu]);

  function scrollElement(item: any) {
    if (item) {
      const currentPosition = item.offsetTop;
      if (currentPosition > window.innerHeight) {
        ref.current.getScrollElement().scrollTop = currentPosition - 300;
      }
    }
  }

  return (
    <React.Fragment>
      <SimpleBar className="h-100" ref={ref}>
        <div id="sidebar-menu">
          <ul className="metismenu list-unstyled" id="side-menu">
            <li className="menu-title">{props.t("Menu")} </li>

            {UserData.user.account === "marketing" && (
              <>
                {(UserData?.user?.role?.admin ||
                  UserData?.user?.access?.find((ac) => ac === "blogs")) && (
                  <li>
                    <Link to="/#" className="has-arrow">
                      <i className="bx bxl-blogger"></i>
                      <span>{props.t("Blog")}</span>
                    </Link>
                    <ul className="sub-menu">
                      {/* <li>
                  <Link to="/blog-list">{props.t("Blog List")}</Link>
                </li> */}
                      <li>
                        <Link to="/blog-grid">
                          <i className="bx bxs-grid"></i>
                          {props.t("All Blogs")}
                        </Link>
                      </li>
                      <li>
                        <Link to="/add-blog">
                          <i className="bx bxs-plus-circle"></i>
                          {props.t("Add Blog")}
                        </Link>
                      </li>

                      {/* <li>
                  <Link to="/blog-details">{props.t("Blog Details")}</Link>
                </li> */}
                    </ul>
                  </li>
                )}
                {(UserData?.user?.role?.admin ||
                  UserData?.user?.access?.find((ac) => ac === "news")) && (
                  <li>
                    <Link to="/#" className="has-arrow">
                      <i className="bx bxs-news"></i>
                      <span>{props.t("Analysis Blog")}</span>
                    </Link>
                    <ul className="sub-menu">
                      <li>
                        <Link to="/analysis-blogs-grid">
                          <i className="bx bxs-grid"></i>
                          {props.t("All Analysis Blogs")}
                        </Link>
                      </li>
                      <li>
                        <Link to="/add-analysis-blogs">
                          <i className="bx bxs-plus-circle"></i>
                          {props.t("Add Analysis Blog")}
                        </Link>
                      </li>
                    </ul>
                  </li>
                )}

                {(UserData?.user?.role?.admin ||
                  UserData?.user?.access?.find(
                    (ac) => ac === "blogs"
                  )) && (
                  <li>
                    <Link to="/#" className="has-arrow">
                      <i className="bx bx-news"></i>
                      <span>{props.t("Market News")}</span>
                    </Link>
                    <ul className="sub-menu">
                      <li>
                        <Link to="/market-news-grid">
                          <i className="bx bxs-grid"></i>
                          {props.t("All Market News")}
                        </Link>
                      </li>
                      <li>
                        <Link to="/add-market-news">
                          <i className="bx bxs-plus-circle"></i>
                          {props.t("Add Market News")}
                        </Link>
                      </li>
                    </ul>
                  </li>
                )}

                {(UserData?.user?.role?.admin ||
                  UserData?.user?.access?.find((ac) => ac === "articles")) && (
                  <li>
                    <Link to="/articles">
                      <i className="bx bxs-info-circle"></i>
                      <span>{props.t("Article Support")}</span>
                    </Link>
                  </li>
                )}

                {(UserData?.user?.role?.admin ||
                  UserData?.user?.access?.find((ac) => ac === "education")) && (
                  <li>
                    <Link to="/educations">
                      <i className="bx bxs-graduation"></i>
                      <span>{props.t("Education")}</span>
                    </Link>
                  </li>
                )}

                {(UserData?.user?.role?.admin ||
                  UserData?.user?.access?.find((ac) => ac === "webinars")) && (
                  <li>
                    <Link to="/webinar">
                      <i className="bx bxs-calendar"></i>
                      <span>{props.t("Schedule Webinars")}</span>
                    </Link>
                  </li>
                )}

                {(UserData?.user?.role?.admin ||
                  UserData?.user?.access?.find((ac) => ac === "seminars")) && (
                  <li>
                    <Link to="/seminar">
                      <i className="bx bxs-calendar"></i>
                      <span>{props.t("Schedule Seminars")}</span>
                    </Link>
                  </li>
                )}

                {(UserData?.user?.role?.admin ||
                  UserData?.user?.access?.find((ac) => ac === "booking")) && (
                  <li>
                    <Link to="/booking">
                      <i className="bx bx-calendar-event"></i>
                      <span>{props.t("Booking")}</span>
                    </Link>
                  </li>
                )}
                {(UserData?.user?.role?.admin ||
                  UserData?.user?.access?.find((ac) => ac === "jobs")) && (
                  <li>
                    <Link to="/#">
                      <span
                        className="badge rounded-pill bg-success float-end"
                        key="t-new"
                      >
                        New
                      </span>
                      <i className="bx bx-briefcase-alt"></i>
                      <span key="t-jobs">{props.t("Jobs")}</span>
                    </Link>
                    <ul className="sub-menu">
                      <li>
                        <Link to="/job-list">{props.t("Job List")}</Link>
                      </li>
                      {/* <li><Link to="/job-details">{props.t("Job Details")}</Link></li> */}
                      {/* <li>
                        <Link to="/#" className="has-arrow">{props.t("Candidate")}</Link>
                        <ul className="sub-menu">
                          <li><Link to="/candidate-list">{props.t("List")}</Link></li>
                          <li><Link to="/candidate-overview">{props.t("Overview")}</Link></li>
                        </ul>
                      </li> */}
                    </ul>
                  </li>
                )}
                {(UserData?.user?.role?.admin ||
                  UserData?.user?.access?.find((ac) => ac === "request")) && (
                  <li>
                    <Link to="/profit/bonus">
                      <i className="bx bxs-dollar-circle"></i>
                      <span> {props.t("Profit Bonus")} </span>
                    </Link>
                  </li>
                )}
                {(UserData?.user?.role?.admin ||
                  UserData?.user?.access?.find((ac) => ac === "approve")) && (
                  <li>
                    <Link to="/approved/profit/bonus">
                      <i className="bx bxs-dollar-circle"></i>
                      <span>{props.t("Approved Requests")}</span>
                    </Link>
                  </li>
                )}
                {(UserData?.user?.role?.admin ||
                  UserData?.user?.access?.find((ac) => ac === "reject")) && (
                  <li>
                    <Link to="/rejected/profit/bonus">
                      <i className="bx bxs-dollar-circle"></i>
                      <span>{props.t("Rejected Requests")}</span>
                    </Link>
                  </li>
                )}
                {(UserData?.user?.role?.admin ||
                  UserData?.user?.access?.find((ac) => ac === "wiki")) && (
                  <li>
                    <Link to="/#" className="has-arrow">
                      <i className="bx bxl-wikipedia"></i>
                      <span>{props.t("Wiki")}</span>
                    </Link>
                    <ul className="sub-menu">
                      <li>
                        <Link to="/all-menus">
                          <i className="bx bx-news"></i>
                          {props.t("Get all Menus")}
                        </Link>
                      </li>
                      <li>
                        <Link to="/add-menu">
                          <i className="bx bxs-plus-circle"></i>
                          {props.t("Add Menu")}
                        </Link>
                      </li>
                    </ul>
                  </li>
                )}
              </>
            )}
            {(UserData?.user?._id == "6638a298a4b129a29873c479" ||
              UserData?.user?.access?.find((ac) => ac === "monday")) && (
              <li>
                <Link to="/monday">
                  <FaCalendarAlt className="mx-1" />
                  <span className="mx-2">{props.t("Monday CRM")}</span>
                </Link>
              </li>
            )}
          </ul>
        </div>
      </SimpleBar>
    </React.Fragment>
  );
};
export default withRouter(withTranslation()(SidebarContent));
