import React, { useEffect, useState } from "react";
import { Col, Container, Row, Alert } from "reactstrap";
import Breadcrumbs from "../../Components/Common/Breadcrumb";
import { Scheduler } from "@aldabil/react-scheduler";
import axios from "axios";
import moment from "moment";

const SeminarCalender = () => {
  const [events, setEvents] = useState([]);
  const [copied, setCopied] = useState(false);
  const [notification, setNotification] = useState("");
  const [showNotification, setShowNotification] = useState(false);
  const UserData = JSON.parse(localStorage.getItem("authUser"));
  console.log(events, "events");

  const isFutureDate = (date) => {
    const now = new Date();
    const currentDate = new Date(date).getTime();
    return currentDate >= now.getTime();
  };

  const handleEventSubmit = async (
    event: any,
    action: string
  ): Promise<any> => {
    console.log("heeelooo");

    return new Promise(async (resolve, reject) => {
      // Your logic here
      console.log("heeelooo");

      // console.log(event, action);
      const startDate = new Date(event.start).getTime();
      const endDate = new Date(event.end).getTime();
      console.log(moment(startDate), "moment");

      if (!isFutureDate(startDate)) {
        setNotification("Cannot schedule events in the past date and time.");
        setShowNotification(true);
        setTimeout(() => setShowNotification(false), 5000);
        return;
      }

      // Calculate difference in milliseconds
      const differenceMs = endDate - startDate;

      // Convert milliseconds to minutes
      const differenceMinutes = Math.floor(differenceMs / (1000 * 60));
      const seminarDetails = {
        topic: event.title,
        start: moment(event.start), // ISO 8601 format
        end: moment(event.end),
        duration: differenceMinutes, // Duration in minutes
      };

      const res = await axios.post(
        `${process.env.REACT_APP_APIKEY}/api/seminar/create`,
        seminarDetails
      );

      console.log(res, "rescreate");

      console.log(event, "event");

      // Dummy resolve to match expected return type
      resolve(event);
    });
  };

  const getAllSeminars = async () => {
    const res = await axios.get(
      `${process.env.REACT_APP_APIKEY}/api/zoom/getSeminars`
    );

    if (res.success) {
      // setEvents(res.data)

      const updatedData = res.data.map((seminars) => {
        console.log(seminars.start);

        // if (seminars.end < seminars.start) {
        return {
          title: seminars.topic,
          start: new Date(seminars.start),
          end: new Date(seminars.end),
        };
        // }
      });
      console.log(updatedData, "updatedData");
      setEvents(updatedData);
    }
  };

  console.log(copied, "copied");

  useEffect(() => {
    getAllSeminars();
  }, []);

  return (
    <div>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs title="Seminars" breadcrumbItem="Full Calendar" />
          <Row className="justify-content-center">
            {copied && <div className="copied-message">Copied</div>}
            {showNotification && (
              <Alert color="danger" style={{ zIndex: "4000" }}>
                {notification}
              </Alert>
            )}
            <Col className="col-12 mt-3 mb-3">
              <Scheduler
                view="month"
                deletable={false}
                // editable={false}
                events={events}
                onConfirm={handleEventSubmit}
              />
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default SeminarCalender;
